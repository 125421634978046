<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
            <b-row>
                <b-col>
                    <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                        {{ $t('elearning_config.training_calendar') + ' ' + $t('globalTrans.details') }}
                        <br>
                        {{ getOrgName(formData.org_id) }}
                        <br>
                        {{ getFiscalYear(formData.fiscal_year_id) }}
                    </list-report-head>
                </b-col>
            </b-row>
            <b-row>
              <b-col lg="12" sm="12" class="text-right" v-if="formData.revise_count > 0" style="font-size: 10px;">
                {{ $t('elearning_config.revised') }} ({{ formData.created_at | dateFormat }})
              </b-col>
              <b-col lg="12" sm="12" class="table-responsive" style="font-size: 10px;">
                <b-table-simple bordered hover small >
                  <tbody>
                    <tr>
                      <th style="width: 5%;">{{ $t('elearning_config.sl_no') }}</th>
                      <th style="width: 5%;">{{ $t('elearning_config.training_title') }}</th>
                      <th style="width: 5%;">{{ $t('elearning_config.no_of_days') }}</th>
                      <th style="width: 5%;">{{ $t('elearning_config.no_of_batch') }}</th>
                      <th style="width: 5%;">{{ $t('elearning_config.jul') }}<br>/{{getFiscalYear(formData.fiscal_year_id).split("-")[0].slice(-2)}}</th>
                      <th style="width: 5%;">{{ $t('elearning_config.aug') }}<br>/{{getFiscalYear(formData.fiscal_year_id).split("-")[0].slice(-2)}}</th>
                      <th style="width: 5%;">{{ $t('elearning_config.sept') }}<br>/{{getFiscalYear(formData.fiscal_year_id).split("-")[0].slice(-2)}}</th>
                      <th style="width: 5%;">{{ $t('elearning_config.oct') }}<br>/{{getFiscalYear(formData.fiscal_year_id).split("-")[0].slice(-2)}}</th>
                      <th style="width: 5%;">{{ $t('elearning_config.nov') }}<br>/{{getFiscalYear(formData.fiscal_year_id).split("-")[0].slice(-2)}}</th>
                      <th style="width: 5%;">{{ $t('elearning_config.dec') }}<br>/{{getFiscalYear(formData.fiscal_year_id).split("-")[0].slice(-2)}}</th>
                      <th style="width: 5%;">{{ $t('elearning_config.jan') }}<br>/{{getFiscalYear(formData.fiscal_year_id).split("-")[1].slice(-2)}}</th>
                      <th style="width: 5%;">{{ $t('elearning_config.feb') }}<br>/{{getFiscalYear(formData.fiscal_year_id).split("-")[1].slice(-2)}}</th>
                      <th style="width: 5%;">{{ $t('elearning_config.mar') }}<br>/{{getFiscalYear(formData.fiscal_year_id).split("-")[1].slice(-2)}}</th>
                      <th style="width: 5%;">{{ $t('elearning_config.apr') }}<br>/{{getFiscalYear(formData.fiscal_year_id).split("-")[1].slice(-2)}}</th>
                      <th style="width: 5%;">{{ $t('elearning_config.may') }}<br>/{{getFiscalYear(formData.fiscal_year_id).split("-")[1].slice(-2)}}</th>
                      <th style="width: 5%;">{{ $t('elearning_config.jun') }}<br>/{{getFiscalYear(formData.fiscal_year_id).split("-")[1].slice(-2)}}</th>
                    </tr>
                    <tr v-for="(item,index) in formData.training_calendar_details" :key="index">
                      <th class="text-left">{{ $n(index +1, { useGrouping: false }) }}</th>
                      <th class="text-left">{{ getTrainingTilteName(item.training_title_id) }}</th>
                      <th class="text-left">{{ $n(item.no_of_days, { useGrouping: false }) }}</th>
                      <th class="text-left">{{ $n(item.no_of_batch, { useGrouping: false }) }}</th>
                      <th style="white-space: nowrap;" class="text-left"><span v-for="(value, index) in item.training_calendar_detail_days" :key="index"><span v-if="getMonthValue(value.start_date) === 7" ><br><span v-if="getValueX(value.start_date, value.end_date) === 1">{{$n(getDateValue(value.start_date))}} - {{$n(getDateValue(value.end_date))}}</span><span v-else> {{$t('elearning_config.start')}} : <br> {{$n(getDateValue(value.start_date))}} {{getMonthName(getMonthValue(value.start_date))}}--{{$n(getDateValue(value.end_date))}} {{getMonthName(getMonthValue(value.end_date))}}</span><br></span><span v-if="getMonthValue(value.end_date) === 7  && getValueX(value.start_date, value.end_date) !== 1 " >{{$t('elearning_config.end')}} :<br> {{$n(getDateValue(value.start_date))}} {{getMonthName(getMonthValue(value.start_date))}}--{{$n(getDateValue(value.end_date))}} {{getMonthName(getMonthValue(value.end_date))}}</span></span></th>
                      <th style="white-space: nowrap;" class="text-left"><span v-for="(value, index) in item.training_calendar_detail_days" :key="index"><span v-if="getMonthValue(value.start_date) === 8" ><br><span v-if="getValueX(value.start_date, value.end_date) === 1">{{$n(getDateValue(value.start_date))}} - {{$n(getDateValue(value.end_date))}}</span><span v-else> {{$t('elearning_config.start')}} : <br> {{$n(getDateValue(value.start_date))}} {{getMonthName(getMonthValue(value.start_date))}}--{{$n(getDateValue(value.end_date))}} {{getMonthName(getMonthValue(value.end_date))}}</span><br></span><span v-if="getMonthValue(value.end_date) === 8  && getValueX(value.start_date, value.end_date) !== 1 " >{{$t('elearning_config.end')}} :<br> {{$n(getDateValue(value.start_date))}} {{getMonthName(getMonthValue(value.start_date))}}--{{$n(getDateValue(value.end_date))}} {{getMonthName(getMonthValue(value.end_date))}}</span></span></th>
                      <th style="white-space: nowrap;" class="text-left"><span v-for="(value, index) in item.training_calendar_detail_days" :key="index"><span v-if="getMonthValue(value.start_date) === 9" ><br><span v-if="getValueX(value.start_date, value.end_date) === 1">{{$n(getDateValue(value.start_date))}} - {{$n(getDateValue(value.end_date))}}</span><span v-else> {{$t('elearning_config.start')}} : <br> {{$n(getDateValue(value.start_date))}} {{getMonthName(getMonthValue(value.start_date))}}--{{$n(getDateValue(value.end_date))}} {{getMonthName(getMonthValue(value.end_date))}}</span><br></span><span v-if="getMonthValue(value.end_date) === 9  && getValueX(value.start_date, value.end_date) !== 1 " >{{$t('elearning_config.end')}} :<br> {{$n(getDateValue(value.start_date))}} {{getMonthName(getMonthValue(value.start_date))}}--{{$n(getDateValue(value.end_date))}} {{getMonthName(getMonthValue(value.end_date))}}</span></span></th>
                      <th style="white-space: nowrap;" class="text-left"><span v-for="(value, index) in item.training_calendar_detail_days" :key="index"><span v-if="getMonthValue(value.start_date) === 10"><br><span v-if="getValueX(value.start_date, value.end_date) === 1">{{$n(getDateValue(value.start_date))}} - {{$n(getDateValue(value.end_date))}}</span><span v-else>  {{$t('elearning_config.start')}} :<br> {{$n(getDateValue(value.start_date))}} {{getMonthName(getMonthValue(value.start_date))}}--{{$n(getDateValue(value.end_date))}} {{getMonthName(getMonthValue(value.end_date))}}</span><br></span><span v-if="getMonthValue(value.end_date) === 10 && getValueX(value.start_date, value.end_date) !== 1 " >{{$t('elearning_config.end')}} :<br> {{$n(getDateValue(value.start_date))}} {{getMonthName(getMonthValue(value.start_date))}}--{{$n(getDateValue(value.end_date))}} {{getMonthName(getMonthValue(value.end_date))}}</span></span></th>
                      <th style="white-space: nowrap;" class="text-left"><span v-for="(value, index) in item.training_calendar_detail_days" :key="index"><span v-if="getMonthValue(value.start_date) === 11"><br><span v-if="getValueX(value.start_date, value.end_date) === 1">{{$n(getDateValue(value.start_date))}} - {{$n(getDateValue(value.end_date))}}</span><span v-else>  {{$t('elearning_config.start')}} :<br> {{$n(getDateValue(value.start_date))}} {{getMonthName(getMonthValue(value.start_date))}}--{{$n(getDateValue(value.end_date))}} {{getMonthName(getMonthValue(value.end_date))}}</span><br></span><span v-if="getMonthValue(value.end_date) === 11 && getValueX(value.start_date, value.end_date) !== 1 " >{{$t('elearning_config.end')}} :<br> {{$n(getDateValue(value.start_date))}} {{getMonthName(getMonthValue(value.start_date))}}--{{$n(getDateValue(value.end_date))}} {{getMonthName(getMonthValue(value.end_date))}}</span></span></th>
                      <th style="white-space: nowrap;" class="text-left"><span v-for="(value, index) in item.training_calendar_detail_days" :key="index"><span v-if="getMonthValue(value.start_date) === 12"><br><span v-if="getValueX(value.start_date, value.end_date) === 1">{{$n(getDateValue(value.start_date))}} - {{$n(getDateValue(value.end_date))}}</span><span v-else>  {{$t('elearning_config.start')}} :<br> {{$n(getDateValue(value.start_date))}} {{getMonthName(getMonthValue(value.start_date))}}--{{$n(getDateValue(value.end_date))}} {{getMonthName(getMonthValue(value.end_date))}}</span><br></span><span v-if="getMonthValue(value.end_date) === 12 && getValueX(value.start_date, value.end_date) !== 1 " >{{$t('elearning_config.end')}} :<br> {{$n(getDateValue(value.start_date))}} {{getMonthName(getMonthValue(value.start_date))}}--{{$n(getDateValue(value.end_date))}} {{getMonthName(getMonthValue(value.end_date))}}</span></span></th>
                      <th style="white-space: nowrap;" class="text-left"><span v-for="(value, index) in item.training_calendar_detail_days" :key="index"><span v-if="getMonthValue(value.start_date) === 1" ><br><span v-if="getValueX(value.start_date, value.end_date) === 1">{{$n(getDateValue(value.start_date))}} - {{$n(getDateValue(value.end_date))}}</span><span v-else> {{$t('elearning_config.start')}} : <br> {{$n(getDateValue(value.start_date))}} {{getMonthName(getMonthValue(value.start_date))}}--{{$n(getDateValue(value.end_date))}} {{getMonthName(getMonthValue(value.end_date))}}</span><br></span><span v-if="getMonthValue(value.end_date) === 1  && getValueX(value.start_date, value.end_date) !== 1 " >{{$t('elearning_config.end')}} :<br> {{$n(getDateValue(value.start_date))}} {{getMonthName(getMonthValue(value.start_date))}}--{{$n(getDateValue(value.end_date))}} {{getMonthName(getMonthValue(value.end_date))}}</span></span></th>
                      <th style="white-space: nowrap;" class="text-left"><span v-for="(value, index) in item.training_calendar_detail_days" :key="index"><span v-if="getMonthValue(value.start_date) === 2" ><br><span v-if="getValueX(value.start_date, value.end_date) === 1">{{$n(getDateValue(value.start_date))}} - {{$n(getDateValue(value.end_date))}}</span><span v-else> {{$t('elearning_config.start')}} : <br> {{$n(getDateValue(value.start_date))}} {{getMonthName(getMonthValue(value.start_date))}}--{{$n(getDateValue(value.end_date))}} {{getMonthName(getMonthValue(value.end_date))}}</span><br></span><span v-if="getMonthValue(value.end_date) === 2  && getValueX(value.start_date, value.end_date) !== 1 " >{{$t('elearning_config.end')}} :<br> {{$n(getDateValue(value.start_date))}} {{getMonthName(getMonthValue(value.start_date))}}--{{$n(getDateValue(value.end_date))}} {{getMonthName(getMonthValue(value.end_date))}}</span></span></th>
                      <th style="white-space: nowrap;" class="text-left"><span v-for="(value, index) in item.training_calendar_detail_days" :key="index"><span v-if="getMonthValue(value.start_date) === 3" ><br><span v-if="getValueX(value.start_date, value.end_date) === 1">{{$n(getDateValue(value.start_date))}} - {{$n(getDateValue(value.end_date))}}</span><span v-else> {{$t('elearning_config.start')}} : <br> {{$n(getDateValue(value.start_date))}} {{getMonthName(getMonthValue(value.start_date))}}--{{$n(getDateValue(value.end_date))}} {{getMonthName(getMonthValue(value.end_date))}}</span><br></span><span v-if="getMonthValue(value.end_date) === 3  && getValueX(value.start_date, value.end_date) !== 1 " >{{$t('elearning_config.end')}} :<br> {{$n(getDateValue(value.start_date))}} {{getMonthName(getMonthValue(value.start_date))}}--{{$n(getDateValue(value.end_date))}} {{getMonthName(getMonthValue(value.end_date))}}</span></span></th>
                      <th style="white-space: nowrap;" class="text-left"><span v-for="(value, index) in item.training_calendar_detail_days" :key="index"><span v-if="getMonthValue(value.start_date) === 4" ><br><span v-if="getValueX(value.start_date, value.end_date) === 1">{{$n(getDateValue(value.start_date))}} - {{$n(getDateValue(value.end_date))}}</span><span v-else> {{$t('elearning_config.start')}} : <br> {{$n(getDateValue(value.start_date))}} {{getMonthName(getMonthValue(value.start_date))}}--{{$n(getDateValue(value.end_date))}} {{getMonthName(getMonthValue(value.end_date))}}</span><br></span><span v-if="getMonthValue(value.end_date) === 4  && getValueX(value.start_date, value.end_date) !== 1 " >{{$t('elearning_config.end')}} :<br> {{$n(getDateValue(value.start_date))}} {{getMonthName(getMonthValue(value.start_date))}}--{{$n(getDateValue(value.end_date))}} {{getMonthName(getMonthValue(value.end_date))}}</span></span></th>
                      <th style="white-space: nowrap;" class="text-left"><span v-for="(value, index) in item.training_calendar_detail_days" :key="index"><span v-if="getMonthValue(value.start_date) === 5" ><br><span v-if="getValueX(value.start_date, value.end_date) === 1">{{$n(getDateValue(value.start_date))}} - {{$n(getDateValue(value.end_date))}}</span><span v-else> {{$t('elearning_config.start')}} : <br> {{$n(getDateValue(value.start_date))}} {{getMonthName(getMonthValue(value.start_date))}}--{{$n(getDateValue(value.end_date))}} {{getMonthName(getMonthValue(value.end_date))}}</span><br></span><span v-if="getMonthValue(value.end_date) === 5  && getValueX(value.start_date, value.end_date) !== 1 " >{{$t('elearning_config.end')}} :<br> {{$n(getDateValue(value.start_date))}} {{getMonthName(getMonthValue(value.start_date))}}--{{$n(getDateValue(value.end_date))}} {{getMonthName(getMonthValue(value.end_date))}}</span></span></th>
                      <th style="white-space: nowrap;" class="text-left"><span v-for="(value, index) in item.training_calendar_detail_days" :key="index"><span v-if="getMonthValue(value.start_date) === 6" >    <span v-if="getValueX(value.start_date, value.end_date) === 1">{{$n(getDateValue(value.start_date))}} - {{$n(getDateValue(value.end_date))}}</span><span v-else> {{$t('elearning_config.start')}} : <br> {{$n(getDateValue(value.start_date))}} {{getMonthName(getMonthValue(value.start_date))}}--{{$n(getDateValue(value.end_date))}} {{getMonthName(getMonthValue(value.end_date))}}</span><br></span><span v-if="getMonthValue(value.end_date) === 6  && getValueX(value.start_date, value.end_date) !== 1 " >{{$t('elearning_config.end')}} :<br> {{$n(getDateValue(value.start_date))}} {{getMonthName(getMonthValue(value.start_date))}}--{{$n(getDateValue(value.end_date))}} {{getMonthName(getMonthValue(value.end_date))}}</span></span></th>
                    </tr>
                  </tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'
export default {
  components: {
    ListReportHead
  },
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      formData: {
        fiscal_year_id: 0,
        org_id: 0
      },
      officeTypeList: [],
      load: false,
      loading: false,
      monthList: [
              {
                value: 7,
                text_en: 'July',
                text_bn: 'জুলাই'
              },
              {
                value: 8,
                text_en: 'Aug',
                text_bn: 'আগস্ট'
              },
              {
                value: 9,
                text_en: 'Sep',
                text_bn: 'সেপ্ট'
              },
              {
                value: 10,
                text_en: 'Oct',
                text_bn: 'অক্টো'
              },
              {
                value: 11,
                text_en: 'Nov',
                text_bn: 'নভে'
              },
              {
                value: 12,
                text_en: 'Dec',
                text_bn: 'ডিস'
              },
              {
                value: 1,
                text_en: 'Jan',
                text_bn: 'জানু'
              },
              {
                value: 2,
                text_en: 'Feb',
                text_bn: 'ফেব্রু'
              },
              {
                value: 3,
                text_en: 'Mar',
                text_bn: 'মার্চ'
              },
              {
                value: 4,
                text_en: 'Apr',
                text_bn: 'এপ্রিল'
              },
              {
                value: 5,
                text_en: 'May',
                text_bn: 'মে'
              },
              {
                value: 6,
                text_en: 'Jun',
                text_bn: 'জুন'
              }
      ],
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl
    }
  },
  computed: {
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  methods: {
    getMonthName (monthId) {
      const objectData = this.monthList.find(type => type.value === parseInt(monthId))
      if (this.$i18n.locale === 'bn') {
        return objectData.text_bn
      } else {
        return objectData.text_en
      }
    },
    getMonthValue (value) {
      const obj = new Date(value).getMonth() + 1
      return obj
    },
    getDateValue (value) {
      const obj = new Date(value).getDate()
      return obj
    },
    getValueX (s, e) {
       const obj = parseInt(parseInt((new Date(e).getMonth() + 1)) - parseInt(new Date(s).getMonth() + 1) + 1)
       return obj
    },
    getValue (id) {
      let p = 0
      this.formData.training_calendar_details.map(item => {
        item.training_calendar_detail_days.map(value => {
        if (new Date(value.start_date).getMonth() + 1 === parseInt(id)) {
              p = p + (new Date(value.end_date).getMonth() + 1) - (new Date(value.start_date).getMonth() + 1)
        }
          })
        })
        if (p > 0) {
          return p + 1
        } else {
          return 1
        }
    },
    getFiscalYear (fisId) {
      const fisYear = this.$store.state.commonObj.fiscalYearList.find(item => item.value === parseInt(fisId))
      if (fisYear === undefined) return ''
      return this.$i18n.locale === 'bn' ? fisYear.text_bn : fisYear.text_en
    },
    getTrainingTilteName (id) {
      const obj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1).find(item => item.value === parseInt(id))
      if (obj === undefined) return ''
      return this.$i18n.locale === 'bn' ? obj.text_bn : obj.text_en
    },
    getOrgName (id) {
      const obj = this.$store.state.commonObj.organizationProfileList.find(item => item.value === parseInt(id))
      if (obj === undefined) return ''
      return this.$i18n.locale === 'bn' ? obj.text_bn : obj.text_en
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    pdfExport () {
      const reportTitle = this.$t('elearning_config.training_calendar') + ' ' + this.$t('globalTrans.details')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.formData, this, this.courses)
    }
  }
}
</script>
